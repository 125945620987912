import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { P } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { Cells } from 'dashboards/common/Cell'
import { SessionCell } from 'dashboards/common/SessionCell'
import { SpinnerWithLabel } from 'common/Spinner'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'
import { sessionsFilterFuture } from 'dashboards/utils/reducers'
import { useMentorDashData, useMentorDashState } from './MentorDashboardState'

export const Meetings: React.FC<NavRouteProps> = ({ route }) => {
  const {
    mentorUid,
    endpoints: {
      mentor: { value: mentor, loading: loadingMentor },
    },
  } = useMentorDashState()
  const [sessions, loadingSessions] = useMentorDashData('sessions', [])

  if (loadingMentor) return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />
  if (!mentor || !(mentor.permission_live_parent_sessions || mentor.permission_reporting)) return <NotAuthorizedPage />

  const futureSessions = sessionsFilterFuture(sessions || []).filter(
    (session) =>
      (session.type === 'parent' && mentor.permission_live_parent_sessions) ||
      (session.type === 'cadet' && mentor.permission_reporting)
  )

  return (
    <Page route={route}>
      <Cells>
        {futureSessions.length > 0 ? (
          futureSessions.map((session, i) => (
            <SessionCell
              key={session.id || i}
              session={session}
              context="future"
              userType="mentor"
              mentorUid={mentorUid || undefined}
            />
          ))
        ) : loadingSessions ? (
          <SpinnerWithLabel color="#858cac" label="One moment please..." />
        ) : (
          <P>No upcoming meetings.</P>
        )}
      </Cells>
    </Page>
  )
}
